import React from 'react'
import './hero.css'
import hero from '../../assets/hero.png'

const Header = () => {
  return (
    <div className='mckw__header' id='home'>
      <div className='mckw__header-content'>
        <div className='mckw__header-txtbgrnd'>
          <h1>Welcome to Our Community Website</h1>
        </div>
        <div className='mckw__header-image'>
          <img src={hero} alt='mckenna woods' />
        </div>
      </div>
    </div>
  )
}

export default Header
