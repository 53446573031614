import '../pages/login.css'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import 'material-icons/iconfont/filled.css'

import { useUserAuth } from '../context/UserAuthContext'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { logIn } = useUserAuth()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      await logIn(email, password)
      navigate('/members')
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <>
      <div className='mckw__login'>
        <div className='mckw__wrapper'>
          <form onSubmit={handleSubmit}>
            <div className='loginTitle'>MKWHOA Login</div>
            <input
              type='email'
              placeholder='Email address'
              contentEditable
              onChange={(e) => setEmail(e.target.value)}
            />

            {error && <Alert variant='danger'>{error}</Alert>}

            <input
              type='password'
              placeholder='Password'
              className='mckw__login_input'
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='mckw__button_wrapper'>
              <button className='loginButton' type='Submit'>
                Log In
              </button>
            </div>
            <div className='mckw__login-back-link'>
              <Link to='/'>Back to home</Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login
