import logo from '../../assets/logo.svg'
import { Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useUserAuth } from '../../context/UserAuthContext'
import React, { useState, useEffect } from 'react'
import 'material-icons/iconfont/filled.css'
import './memnavbar.css'

const MemNavbar = ({ children }) => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

  const auth = 'true'
  if (!auth) {
    ;<Navigate to='/login' />
  }
  const { logOut, user } = useUserAuth()
  const navigate = useNavigate()
  const handleLogout = async () => {
    try {
      await logOut()
      navigate('/')
    } catch (error) {
      console.log(error.message)
    }
  }
  return (
    <>
      <div className='mckw__memnavbar-links_logo'>
        <img src={logo} alt='McKenna Woods logo' />
      </div>
      <nav className='mckw__memnavbar'>
        {(toggleMenu || screenWidth > 1150) && (
          <div className='mckw__memnavbar-links'>
            <div className='mckw__memnavbar-links_container'>
              <a href='#board' className='mckw__memlinkitem'>
                Board Members
              </a>
              <a href='#rules' className='mckw__memlinkitem'>
                Covenants, Rules & Regulations
              </a>
              <a href='#budgets' className='mckw__memlinkitem'>
                Budgets
              </a>
              <a href='#meetings' className='mckw__memlinkitem'>
                Meeting Minutes
              </a>
              <button className='mckw_logout' onClick={handleLogout}>
                Log out
              </button>
            </div>
          </div>
        )}
        <div className='mckw__memnavbar_btn'>
          <button onClick={toggleNav} className='mckw__navbar_menubtn'>
            <span class='material-icons'>menu</span>
          </button>
        </div>
      </nav>
    </>
  )
}

export default MemNavbar
