/* eslint-disable no-octal-escape */
import React from 'react'

import MemNavbar from '../components/memnavbar/MemNavbar'
import presidentgraphic from '../assets/presidentgraphic.png'
import { boardmems } from '../data'
import Boardmem from '../components/Boardmem'

const Members = ({ user }) => {
  return (
    <>
      <div className='mckw__membersnav-wrapper'>
        <MemNavbar />
      </div>
      <div className='mckw__members_header' />

      <div className='mckw__member_header-primary'>
        <h1>MWHOA Announcements</h1>
        <div id='board' className='mckw__member_header-secondary'>
          <h2>2024 Board Members</h2>

          {boardmems.map((boardmem) => (
            <Boardmem key={boardmem.id} boardmem={boardmem} />
          ))}
        </div>
      </div>
      <div className='mckw__member-presidents-wrapper'>
        <div className='mckw__member-presdients_container'>
          <div
            id='presidents'
            className='mckw__member-presidents-header_container'
          >
            <h1>President's Remark</h1>
            <img src={presidentgraphic} alt='talking bubbles grapic' />
          </div>

          <div className='mckw__member-presidents-copy'>
            <p>
              The Board of Directors of the Association intends to use this site
              for members to view important documents regarding our board and
              assoication business. Please take a moment to familiarize yourself
              with the site's contents for a summary of the news and activities
              to-date and any scheduled for the remainder of the year.
            </p>
            <p>
              The Board looks forward to keeping you informed as well as hearing
              from you about developments in the McKenna Woods community. Thank
              you for visiting the website and if you have any comments,
              questions or concerns, reach out to a board member to discuss.
              Thank you!
            </p>
          </div>
        </div>

        <div className='mckw__member-docs_container'>
          <div className='mckw__member-docs-list'>
            <h2>Upcoming Meetings</h2>
            <ul>
<li>September 10, 2024<br />
October 8th, 2024</li>
            </ul>
          <h2>MWHOA Pinic</h2>
          <ul><li>September 21, 2024</li></ul>
            <h2 id='rules'>Covenants, Rules & Regulations</h2>
            <ul>
              <li>
                <a href='docs\ARC_Submittal_Form_1-17-22.pdf' download>
                 Architectural Review Submittal Form
                </a>
              </li>
               <li>
                <a href='docs\Recorded-Covenants-8-20-19-sm.pdf' download>
                  Covenants and Bylaws
                </a>
              </li>
              <li>
                <a href='docs/Electronic-Communications-Rule.pdf' download>
                  Electronic Communications
                </a>
              </li>
              <li>
                <a href='docs\McKenna-Woods-Garage-Sale-Rules.pdf' download>
                  McKenna Woods Garage Sale Rules
                </a>
              </li>
              <li>
                <a href='docs\MWHOA-Rules-and-Regulations-3-12-19.pdf' download>
                  McKenna Woods Rules & Regulations
                </a>
              </li>
            </ul>
            <h2 id='budgets'>Annual Budgets</h2>
            <ul>
               <li>
                <a href='docs\McKenna Woods Financial Update 3-11-2024.pdf' download>
                  2024 Budget
                </a>
              </li>
               <li>
                <a href='docs\2023 Final Budget.pdf' download>
                  2023 Budget
                </a>
              </li>
              <li>
                <a href='docs\Approved-MWHOA-Budget-Fees-2022.pdf' download>
                  2022 Budget
                </a>
              </li>
             
            </ul>
            <h2 id='meetings'>Annual Meeting Minutes</h2>
            <ul>
               <li>
                <a href='docs\2022 Annual Meeting Minutes and Financials 10-18-22.pdf' download>
                  October 18, 2022
                </a>
              </li>
              <li>
                <a href='docs\Annual-Mtg-Min-Fin-10-21-21.pdf' download>
                  October 21, 2021
                </a>
              </li>
             
            </ul>
            <h2>2024 Board of Directors Meeting Minutes</h2>
              <ul>
               <li>
                <a href='docs\McKenna Woods HOA Minutes 3-11-2024.pdf' download> March 11, 2024</a>
              </li>
               <li>
                <a href='docs\McKenna Woods HOA Minutes 1-9-2024.pdf' download> January 9, 2024</a>
              </li>
            
             </ul>
             <h2>2023 Board of Directors Meeting Minutes</h2>
             <ul>
               <li>
                <a href='docs\BOD-Minutes-Financials-9-5-23.pdf' download> September 5, 2023</a>
              </li>
               <li>
                <a href='docs\BOD-Minutes-Financials-7-18-23.pdf' download> July 18, 2023</a>
              </li>
               <li>
                <a href='docs\Board_Minutes_Financials_4_18_23.pdf' download> April 18, 2023</a>
              </li>
               <li>
                <a href='docs\Board_Minutes_Financials_1-17-23.pdf' download> January 17, 2023</a>
              </li>
             </ul>



            <h2>2022 Board of Directors Meeting Minutes</h2>
          <ul>
              <li>
                <a href='docs\Board_Minutes_Financials_11-10-22.pdf' download> November 10, 2022</a>
              </li>
              <li>
                <a href='docs\Board-Meeting-8-30-2022.pdf' download> August 30, 2022</a>
              </li>
              <li>
                <a href='docs\Board-Minutes-Financials-06-28-2022.pdf' download> June 28, 2022</a>
              </li>
              <li>
                <a href='docs\Board-Meeting-5-10-2022.pdf' download> May 10, 2022</a>
              </li>
              <li>
                <a href='docs\Board-Meeting-3-8-22.pdf' download> March 8, 2022</a>

              </li>
              <li><a href='docs\Board-Meeting-1-17-22.pdf' download>January  17, 2022</a></li>
            </ul>
            <h2>2021 Board of Directors Meeting Minutes</h2>
            <ul>
              <li>
                <a href='docs\Board-Minutes-Financials-12-2-21.pdf' download>
                  December 2, 2021
                </a>
              </li>
              <li>
                <a href='docs\Board-Minutes-Financials-8-11-21.pdf' download>
                  August 11, 2021
                </a>
              </li>
              <li>
                <a href='docs\Board-Minutes-Financials-6-28-21.pdf' download>
                  June 28, 2021
                </a>
              </li>
              <li>
                <a href='docs\Board-Minutes-Financials-4-22-21.pdf' download>
                  April 22, 2021
                </a>
              </li>

              <li>
                <a href='docs\Board-of-Directors-Meeting 2-9-21.pdf' download>
                  February 9, 2021
                </a>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Members
