import React from 'react'
import '../components/members.css'

const Boardmem = ({ boardmem }) => {
  return (
    <div className='mckw__member_container'>
      <span className='mckw__member-boardmen-position'>
        {boardmem.position}
      </span>
      <p className='mckw__memeber-boardmen-name'>{boardmem.name}</p>
      <p className='mckw__memeber-boardmen-name'>{boardmem.tel}</p>
    </div>
  )
}

export default Boardmem
