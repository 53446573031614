/* import emailjs from 'emailjs-com' */
import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import './contact.css'
import FormInput from './FormInput'
import contact from '../../assets/contact.png'

const Contact = () => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  })

  const inputs = [
    {
      id: 1,
      name: 'firstName',
      type: 'text',
      placeholder: 'First Name',
      errormessage: 'Only include 3-16 numbers and no special characters',
      label: 'First Name',
      pattern: '^[A-Za-z0-9]{3,16}$',
      required: true
    },
    {
      id: 2,
      name: 'lastName',
      type: 'text',
      placeholder: 'Last Name',
      label: 'Last Name',
      errormessage: 'Only include 3-16 numbers and no special characters',
      // eslint-disable-next-line no-dupe-keys
      label: 'Last Name',
      pattern: '^[A-Za-z0-9]{3,16}$',
      required: true
    },
    {
      id: 3,
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      label: 'Email',
      errormessage: 'Enter a valid email',
      required: true
    },
     {
      id: 4,
      name: 'message',
      placeholder: 'Message',
      label: 'Message',
      errormessage: 'Enter a message',
      required: true
    }
  ]
  const form = useRef()

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  // send data function
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('check form data ===', form.current, e.target.message.value)
    // using emailjs to send emails on the client-side
    emailjs
      .sendForm(
        'service_jyvdy0o',
        'template_at0rrbq',
        form.current,
        'DIStZwTXrroyWdpLE'
      )
      .then(
        (result) => {
          alert('Message sent successfully')
        },
        (error) => {
          alert(error.text)
        }
      )
  }

  return (
    <div className='mckw__contact_container' id='contact'>
      <div className='mckw__contact_container_header' />
      <div className='mckw__contact_container_form'>
        <h2 className='mckw__contact-title'>Contact Us</h2>
        <form ref={form} onSubmit={handleSubmit}>
          {inputs.map(function (input) {
            return <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
              className='mckw__contact-content__input' />
          }
          )}
    
          <div className='mckw__contact-btn'>
            <button type='submit' value='Submit'>
              Send
            </button>
          </div>
        </form>
      </div>
      <div className='contact_container_image'>
        <img src={contact} alt='McKenna Woods Sign' />
      </div>
    </div>
  )
}

export default Contact
