import './App.css'
import Footer from './containers/footer/Footer'
import { Routes, Route } from 'react-router-dom'

import Members from './pages/Members'
import Home from './pages/Home'
import Login from './pages/Login'
import ProtectedRoute from './pages/ProtectedRoute'
import { UserAuthContextProvider } from './context/UserAuthContext'

const App = () => {
  return (
    <>
      <UserAuthContextProvider>
        <Routes>
          <Route
            path='/members'
            element={
              <ProtectedRoute>
                <Members />
              </ProtectedRoute>
            }
          />
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
        </Routes>
      </UserAuthContextProvider>
      <Footer />
    </>
  )
}

export default App
