import logo from '../../assets/logo.svg'
import './navbar.css'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import 'material-icons/iconfont/filled.css'

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

  return (
    <>
      <div className='mckw__navbar-links_logo'>
        <img src={logo} alt='McKenna Woods logo' />
      </div>

      <nav className='mckw__navbar'>
        {(toggleMenu || screenWidth > 900) && (
          <div className='mckw__navbar-links'>
            <div className='mckw__navbar-links_container'>
              <a href='#home' className='mckw__linkitem'>
                Home
              </a>
              <a href='#who' className='mckw__linkitem'>
                About
              </a>
              <a href='#covenants' className='mckw__linkitem'>
                Covenants
              </a>
              <a href='#contact' className='mckw__linkitem'>
                Contact
              </a>
              <Link className='mckw_login' to='login'>
                Homeowner Login
              </Link>
            </div>
          </div>
        )}
        <div className='mckw__navbar_btn'>
          <button onClick={toggleNav} className='mckw__navbar_menubtn'>
            <span class='material-icons'>menu</span>
          </button>
        </div>
      </nav>
    </>
  )
}

export default Navbar
