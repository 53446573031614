import React from 'react'
import './article.css'

const Article = ({ imgUrl, title, text }) => (
  <div className='mckw__card-container_article'>
    <div className='mckw__card-container_article-image'>
      <img src={imgUrl} alt='card_image' />
    </div>
    <div className='mckw__card-container_article-content'>
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  </div>
)
export default Article
