import React from 'react'
import { Article } from '../../components'
import { grass01, grass02 } from './imports'
import './card.css'

const Card = () => {
  return (
    <div className='mckw__card-container'>
      <div className='mckw__card-container_A'>
        <Article
          imgUrl={grass01}
          title='McKenna Woods Homeowners Association'
          text='This unique community consists of upscale single family homes each located on no less than one acre lots. Each home typically has 3 to 5 bedrooms and each must have at least 3 garage spaces. The subdivision includes a park area available to homeowners and their guests. Residents enjoy Plainfield school district grade and high schools, nearby shopping malls and close access to a public bike trail.'
        />
      </div>
      <div className='mckw__card-container_A'>
        <Article
          imgUrl={grass02}
          title='Our Location'
          text='Bounded by the Dupage River on the north and Wedgewood Golf course on the East, McKenna Woods subdivision is located just off Caton Farm road approximately 1/2 mile west of US route 59 in Plainfield Twp., Will County IL.'
        />
      </div>
    </div>
  )
}

export default Card
