import React from 'react'
import './footer.css'
import ScrollToTop from 'react-scroll-to-top'

const Footer = () => {
  return (
    <div>
      <ScrollToTop smooth className='styles-stt' />
      <div className='mckw__footer'>
        <p>©2022 McKenna Woods Plainfield Homeowners Association.</p>
      </div>
    </div>
  )
}

export default Footer
