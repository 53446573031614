import React from 'react'
import './download.css'
// eslint-disable-next-line

const Download = () => {
  return (
    <div className='mckw__download-content' id='covenants'>
      <div className='mckw__download-btn'>
        <button type='button'>
          <a href='docs\Recorded-Covenants-8-20-19-sm.pdf' download>
            Download Convenants
          </a>
        </button>
      </div>
      <div className='mckw__download-image' />
    </div>
  )
}

export default Download
