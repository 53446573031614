// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDjTmV3XJ2QG1lMhGj7vEqIQYbxcMNTpPQ',
  authDomain: 'mkwhoa.firebaseapp.com',
  projectId: 'mkwhoa',
  storageBucket: 'mkwhoa.appspot.com',
  messagingSenderId: '937488468991',
  appId: '1:937488468991:web:293cb43f9a5cd089de35ca',
  measurementId: 'G-ZK10Q3M823'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export default app
