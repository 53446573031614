import React from 'react'
import './feature.css'

const Feature = ({ title, text }) => (
  <div className='mckw__features-container__feature'>
    <div className='mckw__features-container__feature-title'>
      <div />
      <h2>{title}</h2>
    </div>
    <div className='mckw__features-container_feature-text'>
      <p>{text}</p>
    </div>
  </div>
)

export default Feature
