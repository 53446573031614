import React, { useState } from 'react'
import './contact.css'

const FormInput = (props) => {
  const [focused, setFocused] = useState(false)
  const { label, errormessage, onChange, id, ...inputProps } = props

  const handleFocus = (e) => {
    setFocused(true)
  }
  return (
    <div className='mckw__contact-content_input'>
      <label>{label}</label>
      <input
        {...inputProps}
        onChange={onChange}
        onBlur={handleFocus}
        focused={focused.toString()}
      />

      <p>{errormessage}</p>
    </div>
  )
}

export default FormInput
