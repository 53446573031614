export const boardmems = [
  {
    id: 1,
    position: 'President',
    name: 'Tommie Van',
    tel: '630-870-0120'
  },
  {
    id: 2,
    position: 'Secretary',
    name: 'Erik Schwarz',
    tel: '815-761-4224'
  },
  {
    id: 3,
    position: 'Treasurer',
    name: 'Larry Wiet',
    tel: '630-730-2182'
  },
  {
    id: 4,
    position: 'Vice President',
    name: 'John Paul Ivec',
    tel: '815-212-3706'
  },
  {
    id: 5,
    position: 'Vice President',
    name: 'John Evans',
    tel: '815-263-7161'
  }
]

export const presidents = [
  {
    id: 1,
    title: 'Presidents Remarks',
    img: 'src/assets/presidentgraphic.png',
    copy: 'Welcome to your McKenna Woods Homeowners Association (MWHOA) website! The Board of Directors of the Association intends to use this site for members to view important document regarding our board and assoicatio business.\\n\\nPlease take a moment to familiarize yourself with the site’s contents for a summary of the news and activities to-date and any scheduled for the remainder of the year. \\n\\nThe Board looks forward to keeping you informed as well as hearing from you about developments in the McKenna Woods community. Thank you for visiting the website and if you have any suggestions on a particular feature you would like to see added to the website, reach ou to a board member to discuss.'
  }
]
