import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { Card, Features, Hero, Who, Contact } from '../containers'
import Download from '../components/download/Download'

function Home () {
  return (
    <div>
      <Navbar />
      <Hero />
      <Card />
      <Features />
      <Download />
      <Who />
      <Contact />
    </div>
  )
}

export default Home
