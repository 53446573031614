import React from 'react'
import Feature from '../../components/feature/Feature'
import './who.css'
import about from '../../assets/about.png'

const Who = () => {
  return (
    <div className='mckw__who' id='who'>
      <div className='mckw__who-heading-content'>
        <div className='mckw__who-heading'>
          <h2>About McKenna</h2>
          <p>
            McKenna Woods subdivision operates through its homeowners
            Association. The Association in cooperation with its members is
            charged with creating and maintaining high property values as well
            as insuring high standards of maintenance and operation of the
            subdivision.
          </p>
        </div>
        <div className='mckw__who-image'>
          <img src={about} alt='MWHOA Community graphic' />
        </div>
      </div>
      <div className='mckw__who-container'>
        <Feature
          title='The stated goal of the subdivision covenants may be summed up as follows:'
          text='To preserve the general welfare, health and safety of residents as well as the beauty of its lands and real estate so as to create and maintain property values and a high quality of life for residents. McKenna Woods was designed to be a special place to live and to give the word “HOME” a distinctive and unique meaning to its residents.'
        />
      </div>
    </div>
  )
}

export default Who
